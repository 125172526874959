// @flow
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ProfileDropdown from './ProfileDropdown';
import { showRightSidebar } from '../redux/actions';

import profilePic from '../assets/images/users/user-profile.png';
import logoSm from '../assets/images/EDGE_Boost_Logo_v2-sm.png';

const ProfileMenus = [
    {
        label: 'Logout',
        icon: 'uil uil-exit',
        redirectTo: '/account/logout',
    },
];
const MinimalTopBar = ({ user, showRightSidebar, hideLogo, navCssClasses, openLeftMenuCallback }) => {
    const containerCssClasses = !hideLogo ? 'container-fluid' : '';

    return (
        <>
            <div className={`navbar-minimal ${navCssClasses}`}>
                <div className={containerCssClasses}>
                    <div className="topnav-logo mt-1">
                        <span className="topnav-logo-sm">
                            <img src={logoSm} alt="logo" height="24" />
                        </span>
                    </div>
                    <h3 className="center-wizard-title">Account Under Review</h3>
                    <ul className="list-unstyled topbar-right-menu float-right mb-0">
                        <li className="notification-list">
                            <ProfileDropdown
                                profilePic={profilePic}
                                menuItems={ProfileMenus}
                                username={user?.name}
                                userTitle={user?.level ? user?.level.label : ''}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MinimalTopBar;